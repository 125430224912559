/* Main DateRangePicker Styles */
.custom-date-range {
    background-color: #f0f0f0 !important;
    /* Background color */
    border: 1px solid #FC5719 !important;
    /* Border color */
    border-radius: 8px !important;
    /* Rounded corners */
    color: #333 !important;
    /* Text color */
    padding: 8px 12px !important;
}

/* Change calendar icon color */
.custom-date-range .rs-picker-toggle-caret {
    color: #FC5719 !important;
}

/* Change selected date range background */
.custom-date-range .rs-calendar-table-cell-selected {
    background-color: #FC5719 !important;
    color: #fff !important;
}

/* Hover effect */
.custom-date-range .rs-picker-toggle:hover {
    background-color: #ffe6e1 !important;
}

/* Focus and Disabled Styles */
.custom-date-range:focus {
    border-color: #FC5719 !important;
    box-shadow: 0 0 5px rgba(252, 87, 25, 0.5);
}

.custom-date-range:disabled {
    background-color: #e0e0e0 !important;
    color: #aaa !important;
}

/* Active Month Dropdown Cell */
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content {
    background-color: #FC5719 !important;
}
.rs-calendar-month-dropdown-cell-active .rs-calendar-month-dropdown-cell-content:hover {
    background-color: #FC5719 !important;
}

.rs-picker-popup .rs-calendar-table-cell:focus-visible .rs-calendar-table-cell-content{
    outline: 3px solid rgb(from #FC5719 r g b / 25%) !important;
        outline: 3px solid var(--rs-color-focus-ring) !important;
    
}

/* Toolbar Button Right Side */
.rs-picker-toolbar-right button {
    background-color: #FC5719 !important;
}

/* Active Year in Month Dropdown */
.rs-calendar-month-dropdown-year-active {
    color: #FC5719 !important;
}

/* Link Button (e.g., OK/Cancel buttons) */
.rs-btn-link {
    color: #FC5719 !important;
}


/* Selected Cell Background */
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #FC5719 !important;
}
.rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
    background-color: #ffe6e1 !important;
    color: #FC5719 !important;
}
.rs-calendar-month-view .rs-calendar-header-title-date{
    color: #FC5719 !important;
}
/* Hover Effect on Selected Date Cell */
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content:hover {
    background-color: #FC5719 !important;
    color: #fff !important;
}

/* Hover Effect on All Date Cells */
.rs-calendar-table-cell-content:hover {
    background-color: #ffe6e1 !important;
    color: #FC5719 !important;
}

/* Disable Hover Text Color Override */
.rs-calendar-table-cell-content:hover {
    color: none;
}

/* Highlight Today's Date */
.rs-calendar-table-cell .rs-calendar-table-cell-is-today {
    border: #FC5719 !important;
}

/* Highlight In-Range Date Background */
.rs-calendar-table-cell-in-range:before {
    background-color: #ffe6e1 !important;
}

/* Range Cell Background */
.rs-calendar-table-cell.rs-calendar-table-cell-in-range {
    background-color: #ffe6e1 !important;
    color: #000 !important;
}

/* Active Start/End Tab Header Border */
.rs-picker-daterange-header.rs-picker-tab-active-end:after,
.rs-picker-daterange-header.rs-picker-tab-active-start:after {
    border-bottom-color: #FC5719 !important;
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus,
.rs-input-group:not(.rs-input-group-disabled):hover{
    border-color: #FC5719 !important;
}